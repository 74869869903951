import React from "react";
import {graphql} from "gatsby";
import {MDXRenderer} from "gatsby-plugin-mdx";

export default function Template({
                                     data, // this prop will be injected by the GraphQL query below.
                                 }) {
    return (
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
    )
};

export const pageQuery = graphql`
    query PlainLayoutPageQuery($slug: String) {
        mdx(frontmatter: {slug: {eq: $slug}}) {
            id
            body
             frontmatter {
                    slug
                    title
                    description
                  }
        }
    }
`;
